<template>
  <div class="content-wrapper__layout">
    <side-nav></side-nav>

    <div class="content-wrapper__main-content-wrapper content-wrapper__main-content-wrapper--13">
      <system-nav></system-nav>
      <section class="main-content">
        <div class="grid grid-1">
          <div class="card" v-for="group in groups" :key="group.id">
            <div class="card__top">
              <h3 class="card__title">{{ group.name }}</h3>
            </div>
            <div class="card__middle">
              <p>{{ group.description }}</p>
            </div>
            <div class="card__bottom">
              <router-link class="btn btn--primary btn--outline btn--sm" :to="{ name: 'Group', params: { groupId: group.id } }">Administrar</router-link>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import SideNav from '@/components/SideNav.vue'
import SystemNav from '@/components/SystemNav.vue'

export default {
  components: { SideNav, SystemNav },
  name: 'GroupsAndPermissions',
  data() {
    return {
      groups: [
        {
          id: 1,
          name: 'Administradores',
          description: 'Los administradores pueden administrar todo el sistema'
        }
        // {
        //   id: 2,
        //   name: 'Usuarios',
        //   description: 'Los usuarios pueden acceder a todo el sistema'
        // },
        // {
        //   id: 3,
        //   name: 'Clientes',
        //   description: 'Los clientes pueden acceder a todo el sistema'
        // }
      ]
    }
  }
}
</script>