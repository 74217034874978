<template>
  <div class="sysnav">
    <h5 class="sysnav__title">Opciones del sistema</h5>
    <div class="sysnav__items">
      <router-link :class="['sysnav__item', $route.meta.subsection == 'grupos-y-permisos' ? 'sysnav__item--active' : '']" :to="{ name: 'GroupsAndPermissions' }">Grupos y permisos</router-link>
      <router-link :class="['sysnav__item', $route.meta.subsection == 'causas' ? 'sysnav__item--active' : '']" :to="{ name: 'Causes' }">Causas</router-link>
      <router-link :class="['sysnav__item', $route.meta.subsection == 'areas-de-interes' ? 'sysnav__item--active' : '']" :to="{ name: 'AreasOfInterest' }">Áreas de interés</router-link>
      <router-link :class="['sysnav__item', $route.meta.subsection == 'etiquetas' ? 'sysnav__item--active' : '']" :to="{ name: 'Tags' }">Etiquetas</router-link>
    </div>
  </div>
</template>

<script>
  export default {
    name: "SystemNav"
  }
</script>